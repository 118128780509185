import * as React from "react";
import { Router } from "@reach/router"
import StarPage from "./star";
import GalaxyPage from "./galaxy";
import SEO from "../../components/layout/seo";
import { useWebGLCheck } from "../../hooks/useWebGLCheck";

type Props = {
    basePath?: string
}
const GalaxyApp : React.FC<Props> = ({basePath}) => {

     //WebGL check
     const { modal, isSupported } = useWebGLCheck();

     if(!isSupported) {
         return <>{modal}</>;
     }

    return (
        <>
            <SEO    
                title="Star Stories"
                image="/images/seo/starstories.png"
                description="Explore a galaxy full of culture and history."
            />
            <Router basepath={basePath ?? "/galaxy"}>
                <GalaxyPage path="/" />
                <StarPage path="/star" />
            </Router>
        </>

    );
}

export default GalaxyApp;